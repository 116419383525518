@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@200;400;600;700&family=Roboto:wght@300;400;500;700&display=swap");

html {
  margin: 0;
  overscroll-behavior: none;

  -webkit-tap-highlight-color: transparent;
}

button {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

.styled-dropdown {
  text-indent: 10px;
  border: none;
  border-radius: 3px;
  -webkit-flex: 0 0 42px;
  -ms-flex: 0 0 42px;
  flex: 0 0 42px;
  box-shadow: 0 2px 10px #8597b0;
}

.styled-textarea {
  text-indent: 10px;
  border: none;
  border-radius: 3px;
  -webkit-flex: 0 0 42px;
  -ms-flex: 0 0 42px;
  flex: 0 0 300px;
  box-shadow: 0 2px 10px #8597b0;
  border-radius: 5px;
  font-family: "Roboto", sans-serif;
  padding-top: 10px;
  font-size: 14px;
}

/* auto complete container */
.pac-container {
  z-index: 3000 !important;
}
